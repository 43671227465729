let ASSET_URL = "";
let SITE_URL = "";
let APP_URL = "";
let SSO_URL = "";
let LMS_URL = "";
if (process.env.REACT_APP_ENV === "uat") {
  SITE_URL = "https://uat.restthecase.com";
  APP_URL = "https://uat.admin.restthecase.com";
  SSO_URL = "https://uat.sso.restthecase.com";
  LMS_URL = "https://uat.lms.restthecase.com";
  ASSET_URL = "https://rtc-uat.s3.ap-south-1.amazonaws.com";
}else if (process.env.REACT_APP_ENV === "stage") {
  SITE_URL = "https://stage.restthecase.com";
  APP_URL = "https://stage.admin.restthecase.com";
  SSO_URL = "https://stage.sso.restthecase.com";
  LMS_URL = "https://dev.lms.restthecase.com";
  ASSET_URL = "https://rtc-dev.s3.ap-south-1.amazonaws.com";
}  else {
  SITE_URL = "https://restthecase.com";
  APP_URL = "https://admin.restthecase.com";
  SSO_URL = "https://sso.restthecase.com";
  LMS_URL = "https://lms.restthecase.com";
  ASSET_URL = "https://rtc-prod.s3.ap-south-1.amazonaws.com";
}

export enum Modules {
  Role = "Role",
  Team = "Team",
  LMS = "LMS",
  Review = "Review",
  DocumentTemplate = "DocumentTemplate",
  DocumentResource = "DocumentResource",
  Category = "Category",
  City = "City",
  Client = "Client",
  Section = "Section",
  Lawyer = "Lawyer",
  Page = "Page",
  Attribute = "Attribute",
  Appointment = "Appointment",
  Type = "Type",
  Subscription = "Subscription",
  KnowledgeBank = "KnowledgeBank",
  Subscriber = "Subscriber",
  Package = "Package",
  LegalDocument="LegalDocument"
}

export enum ModelPermission {
  Read = "Read",
  Write = "Write",
  Edit = "Edit",
  Remove = "Remove",
}

interface AnyObject {
  [key: string]: any;
}
export const createPayload = (obj: AnyObject) => {
  if (obj === undefined || obj === null) {
    return obj;
  }
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === "object") {
        if (obj[key] !== null) {
          createPayload(obj[key]);
          // Check if the object is empty after removing its properties
          if (Object.keys(obj[key]).length === 0) {
            delete obj[key];
          }
        } else {
          delete obj[key]; // Delete property if value is null
        }
      } else if (obj[key] === "" || obj[key] === null) {
        // Check for null values
        delete obj[key];
      }
    }
  }
  return obj;
};
export { SITE_URL, ASSET_URL, APP_URL, SSO_URL, LMS_URL };
