import * as routePaths from "./RouteConstant";
import { RouteObject } from "react-router-dom";
import React, { Suspense, useContext } from "react";
import { AppContext } from "./App";
import { Layout } from "./shared/Layout";

const PageNotFound = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.PageNotFound,
  }))
);

const AdminDashboard = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.AdminDashboard,
  }))
);

const Profile = React.lazy(() => {
  return import("./pages").then((module) => ({
    default: module.Profile,
  }));
});

const CategoryListPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.CategoryListPage,
  }))
);
const CategoryAddPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.CategoryAddPage,
  }))
);
const CityListPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.CityListPage,
  }))
);
const CityAddPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.CityAddPage,
  }))
);

const TeamListPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.TeamListPage,
  }))
);

const TeamAddPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.TeamAddPage,
  }))
);

const LawyerListPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.LawyerListPage,
  }))
);
const LawyerAddPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.LawyerAddPage,
  }))
);
const SectionListPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.SectionListPage,
  }))
);
const SectionAddPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.SectionAddPage,
  }))
);
const WebsitePageListPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.WebsitePageListPage,
  }))
);
const WebsitePageAddPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.WebsitePageAddPage,
  }))
);
const ReviewListPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.ReviewListPage,
  }))
);
const ReviewAddPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.ReviewAddPage,
  }))
);

const PackageListPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.PackageListPage,
  }))
);
const PackageAddPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.PackageAddPage,
  }))
);
const RoleListPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.RolesListPage,
  }))
);

const RoleAddPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.RolesAddPage,
  }))
);

const AttributeListPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.AttributeListPage,
  }))
);
const AddAttributePage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.AddAttributePage,
  }))
);
const DocumentTemplateListPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.DocumentTemplateListPage,
  }))
);
const AddDocumentTemplatePage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.AddDocumentTemplatePage,
  }))
);
const TypeListPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.TypeListPage,
  }))
);
const AddTypePage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.AddTypePage,
  }))
);
const ClientListPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.ClientListPage,
  }))
);
const SubscriptionListPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.SubscriptionListPage,
  }))
);
const SubscriptionAddPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.SubscriptionAddPage,
  }))
);
const KnowledgebankListPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.KnowledgebankListPage,
  }))
);
const KnowledgebankAddPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.KnowledgebankAddPage,
  }))
);
const SubscriberListPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.SubscriberListPage,
  }))
);

const SettingPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.Setting,
  }))
);
const RedirectUrlListPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.RedirectUrlListPage,
  }))
);
const RedirectUrlAddPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.RedirectUrlAddPage,
  }))
);
const LegalDocumentListPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.LegalDocumentListPage,
  }))
);
const LegalDocumentAddPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.LegalDocumentAddPage,
  }))
);

const AuthRoutes = ({ component }: any) => {
  const { state } = useContext<any>(AppContext);
  const isLoading = state?.app_loading;

  if (isLoading) return null;

  return (
    <>
      {!isLoading ? (
        <Layout>
          <Suspense fallback={"Loading"}>{component}</Suspense>
        </Layout>
      ) : null}
    </>
  );
};

export const routes: RouteObject[] = [
  {
    path: "/",
    children: [
      {
        path: routePaths.DASHBOARD,
        element: <AuthRoutes component={<AdminDashboard />} />,
      },
      {
        path: routePaths.PROFILE,
        element: <AuthRoutes component={<Profile />} />,
      },
      {
        path: routePaths.CATEGORY_LIST,
        element: <AuthRoutes component={<CategoryListPage />} />,
      },
      {
        path: routePaths.CATEGORY_ADD,
        element: <AuthRoutes component={<CategoryAddPage />} />,
      },
      {
        path: routePaths.CATEGORY_EDIT,
        element: <AuthRoutes component={<CategoryAddPage />} />,
      },
      {
        path: routePaths.CITY_LIST,
        element: <AuthRoutes component={<CityListPage />} />,
      },
      {
        path: routePaths.CITY_ADD,
        element: <AuthRoutes component={<CityAddPage />} />,
      },
      {
        path: routePaths.CITY_EDIT,
        element: <AuthRoutes component={<CityAddPage />} />,
      },
      {
        path: routePaths.TEAM_LIST,
        element: <AuthRoutes component={<TeamListPage />} />,
      },
      {
        path: routePaths.TEAM_ADD,
        element: <AuthRoutes component={<TeamAddPage />} />,
      },
      {
        path: routePaths.TEAM_EDIT,
        element: <AuthRoutes component={<TeamAddPage />} />,
      },
      {
        path: routePaths.LAWYER_LIST,
        element: <AuthRoutes component={<LawyerListPage />} />,
      },
      {
        path: routePaths.LAWYER_ADD,
        element: <AuthRoutes component={<LawyerAddPage />} />,
      },
      {
        path: routePaths.LAWYER_EDIT,
        element: <AuthRoutes component={<LawyerAddPage />} />,
      },
      {
        path: routePaths.SECTION_LIST,
        element: <AuthRoutes component={<SectionListPage />} />,
      },
      {
        path: routePaths.SECTION_ADD,
        element: <AuthRoutes component={<SectionAddPage />} />,
      },
      {
        path: routePaths.SECTION_EDIT,
        element: <AuthRoutes component={<SectionAddPage />} />,
      },
      {
        path: routePaths.PAGE_LIST,
        element: <AuthRoutes component={<WebsitePageListPage />} />,
      },
      {
        path: routePaths.PAGE_ADD,
        element: <AuthRoutes component={<WebsitePageAddPage />} />,
      },
      {
        path: routePaths.PAGE_EDIT,
        element: <AuthRoutes component={<WebsitePageAddPage />} />,
      },
      {
        path: routePaths.PACKAGES_LIST,
        element: <AuthRoutes component={<PackageListPage />} />,
      },
      {
        path: routePaths.PACKAGES_ADD,
        element: <AuthRoutes component={<PackageAddPage />} />,
      },
      {
        path: routePaths.PACKAGES_EDIT,
        element: <AuthRoutes component={<PackageAddPage />} />,
      },
      {
        path: routePaths.REVIEW_LIST,
        element: <AuthRoutes component={<ReviewListPage />} />,
      },
      {
        path: routePaths.REVIEW_ADD,
        element: <AuthRoutes component={<ReviewAddPage />} />,
      },
      {
        path: routePaths.ROLE_LIST,
        element: <AuthRoutes component={<RoleListPage />} />,
      },
      {
        path: routePaths.ROLE_ADD,
        element: <AuthRoutes component={<RoleAddPage />} />,
      },
      {
        path: routePaths.ROLE_EDIT,
        element: <AuthRoutes component={<RoleAddPage />} />,
      },
      {
        path: routePaths.TYPE_LIST,
        element: <AuthRoutes component={<TypeListPage />} />,
      },
      {
        path: routePaths.TYPE_ADD,
        element: <AuthRoutes component={<AddTypePage />} />,
      },
      {
        path: routePaths.TYPE_EDIT,
        element: <AuthRoutes component={<AddTypePage />} />,
      },
      {
        path: routePaths.ATTRIBUTE_LIST,
        element: <AuthRoutes component={<AttributeListPage />} />,
      },
      {
        path: routePaths.ATTRIBUTE_ADD,
        element: <AuthRoutes component={<AddAttributePage />} />,
      },
      {
        path: routePaths.ATTRIBUTE_EDIT,
        element: <AuthRoutes component={<AddAttributePage />} />,
      },
      {
        path: routePaths.DOCUMENT_TEMPLATE_LIST,
        element: <AuthRoutes component={<DocumentTemplateListPage />} />,
      },
      {
        path: routePaths.DOCUMENT_TEMPLATE_ADD,
        element: <AuthRoutes component={<AddDocumentTemplatePage />} />,
      },
      {
        path: routePaths.DOCUMENT_TEMPLATE_EDIT,
        element: <AuthRoutes component={<AddDocumentTemplatePage />} />,
      },
      {
        path: routePaths.CLIENT_LIST,
        element: <AuthRoutes component={<ClientListPage />} />,
      },
      {
        path: routePaths.SUBSCRIPTIN_LIST,
        element: <AuthRoutes component={<SubscriptionListPage />} />,
      },
      {
        path: routePaths.SUBSCRIPTIN_ADD,
        element: <AuthRoutes component={<SubscriptionAddPage />} />,
      },
      {
        path: routePaths.SUBSCRIPTIN_EDIT,
        element: <AuthRoutes component={<SubscriptionAddPage />} />,
      },
      {
        path: routePaths.KNOWLEDGEBANK_LIST,
        element: <AuthRoutes component={<KnowledgebankListPage />} />,
      },
      {
        path: routePaths.KNOWLEDGEBANK_ADD,
        element: <AuthRoutes component={<KnowledgebankAddPage />} />,
      },
      {
        path: routePaths.KNOWLEDGEBANK_EDIT,
        element: <AuthRoutes component={<KnowledgebankAddPage />} />,
      },
      {
        path: routePaths.SUBSCRIBER_LIST,
        element: <AuthRoutes component={<SubscriberListPage />} />,
      },
      {
        path: routePaths.SETTING,
        element: <AuthRoutes component={<SettingPage />} />,
      },
      {
        path: routePaths.REDIRECTURL_LIST,
        element: <AuthRoutes component={<RedirectUrlListPage />} />,
      },
      {
        path: routePaths.REDIRECTURL_ADD,
        element: <AuthRoutes component={<RedirectUrlAddPage />} />,
      },
      {
        path: routePaths.REDIRECTURL_EDIT,
        element: <AuthRoutes component={<RedirectUrlAddPage />} />,
      },
      {
        path: routePaths.LEGAL_DOCUMENT_LIST,
        element: <AuthRoutes component={<LegalDocumentListPage />} />,
      },
      {
        path: routePaths.LEGAL_DOCUMENT_ADD,
        element: <AuthRoutes component={<LegalDocumentAddPage />} />,
      },
      {
        path: routePaths.LEGAL_DOCUMENT_EDIT,
        element: <AuthRoutes component={<LegalDocumentAddPage />} />,
      },
    ],
  },
  {
    path: "*",
    element: (
      <Suspense fallback={"Loading"}>
        <PageNotFound />
      </Suspense>
    ),
  },
];
